import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  components: {
    PanelButton: function PanelButton() {
      return import('~/components/panels/PanelButton.vue');
    }
  },
  computed: {
    authPanelStore: function authPanelStore() {
      return useAuthPanelStore();
    },
    isCompanies: function isCompanies() {
      return this.$route.path.match('/community') && (!this.$route.query.segment || this.$route.query.segment == 'organizations');
    }
  },
  methods: {
    login: function login() {
      this.authPanelStore.$patch({
        direction: 'right',
        state: 'login'
      });
    }
  }
};